import "./Scaps.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'

function ScapsTemplate(props) {
    return (
        <div className={"page scaps"}>
            {props.children}
        </div>
    );
}

export default ScapsTemplate;
