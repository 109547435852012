import graphImage from '../../assets/step6/graph.svg';
import Step from "../../components/step/Step";
import {useIntl} from "react-intl";
import {Box, Heading, Image} from "@chakra-ui/react";
import NextButton from "../../components/next-button/NextButton";

function Step6() {
    const intl = useIntl();

    return (
        <Step currentStep={6}
              backLink={'/step5'}>
            <Box textAlign={'left'}>
                <Heading fontSize={'xl'}>
                    {intl.formatMessage({id: 'step6.headline'})}
                </Heading>

                <Heading mt={5} fontSize={'lg'}>
                    {intl.formatMessage({id: 'step6.subHeadline'})}
                </Heading>

                <Box mt={4} position={'relative'}>
                    <Box position={'absolute'}
                         right={2}
                         top={'48%'}
                         fontSize={['lg','xx-large']}
                         fontWeight={'bold'}
                         color={'red'}>
                        {intl.formatMessage({id: 'step6.graph.diet'})}
                    </Box>
                    <Image width={'100%'} src={graphImage}/>
                </Box>
            </Box>

            <NextButton redirect={'step7'}/>
        </Step>
    );
}

export default Step6;
