import {Image, SimpleGrid, Text} from "@chakra-ui/react";
import Step from "../../components/step/Step";
import {useNavigate} from "react-router-dom";
import maleImage from '../../assets/step1/male.svg';
import femaleImage from '../../assets/step1/female.svg';
import {useIntl} from "react-intl";
import useStore from "../../storage/DataStore";
import GenderCard from "../../components/gender-card/GenderCard";

function Step1() {
    let navigate = useNavigate();
    const intl = useIntl();

    const nextStep = function (gender) {
        useStore.setState({
            gender: gender
        });

        navigate('/step2', {replace: true});
    }

    return (
        <Step heading={intl.formatMessage({id: 'step1.headline'})}
              description={intl.formatMessage({id: 'step1.description'})}
              currentStep={1}
              backLink={'/'}>

            <SimpleGrid mt={5} spacing={2} columns={2}>
                <GenderCard onClick={() => nextStep('male')}>
                    <Text fontWeight={'bold'}>{intl.formatMessage({id: 'step1.option1'})}</Text>
                    <Image display={'inline-block'} src={maleImage}/>
                </GenderCard>
                <GenderCard onClick={() => nextStep('female')}>
                    <Text fontWeight={'bold'}>{intl.formatMessage({id: 'step1.option2'})}</Text>
                    <Image display={'inline-block'} src={femaleImage}/>
                </GenderCard>
            </SimpleGrid>
        </Step>
    );
}

export default Step1;
