import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import Dutch from './lang/nl.json';
import French from './lang/fr.json';

import ChakraTheme from "./templates/Chakra";
import ScapsTemplate from "./templates/Scaps";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Loader from "./pages/loader/Loader";

import genderMale from './assets/step1/male.svg';
import genderFemale from './assets/step1/female.svg';
import maleBody1 from './assets/step7/bodytype-male-1.svg';
import maleBody2 from './assets/step7/bodytype-male-2.svg';
import maleBody3 from './assets/step7/bodytype-male-3.svg';
import femaleBody1 from './assets/step7/bodytype-female-1.svg';
import femaleBody2 from './assets/step7/bodytype-female-2.svg';
import femaleBody3 from './assets/step7/bodytype-female-3.svg';
import graphImage from './assets/step6/graph.svg';

import useImagePreloader from "./hooks/UseImagePreloader";

function App() {
    const currentUrl = window.location.toString();

    const preloadSrcList = [
        genderMale,
        genderFemale,
        maleBody1,
        maleBody2,
        maleBody3,
        femaleBody1,
        femaleBody2,
        femaleBody3,
        graphImage
    ];

    useImagePreloader(preloadSrcList)

    let messages = German;
    let locale = 'de';
    moment.locale('de');

    switch (true) {
        case (currentUrl.search('/at') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/at/jetzt-kaufen.html';
            //messages['step5.headline'] = 'Anna aus Wien';
            //messages['step10.headline'] = 'Svenja aus Graz';
            break;

        case (currentUrl.search('/ch') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/ch/jetzt-kaufen.html';
            //messages['step5.headline'] = 'Anna aus Basel';
            //messages['step10.headline'] = 'Svenja aus Zürich';
            locale = 'de-ch';
            break;

        case (currentUrl.search('/fr') !== -1):
            messages = French;
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/nl') !== -1):
            messages = Dutch;
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/befr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://s-caps.com/befr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        case (currentUrl.search('/benl') !== -1):
            messages = Dutch;
            messages.outgoingUrl = 'https://s-caps.com/benl/nu-kopen.html';
            locale = 'nl';
            moment.locale('nl');
            break;

        case (currentUrl.search('/lude') !== -1):
            messages.outgoingUrl = 'https://s-caps.com/lude/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/lufr') !== -1):
            messages = French;
            messages.outgoingUrl = 'https://s-caps.com/lufr/acheter-maintenant.html';
            locale = 'fr';
            moment.locale('fr');
            break;

        default:
    }

    document.title = messages.title + ' | Shape Kapseln®';

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <ScapsTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2/>
                            }/>
                            <Route path='/step3' element={
                                <Step3/>
                            }/>
                            <Route path='/step4' element={
                                <Step4/>
                            }/>
                            <Route path='/step5' element={
                                <Step5/>
                            }/>
                            <Route path='/step6' element={
                                <Step6/>
                            }/>
                            <Route path='/step7' element={
                                <Step7/>
                            }/>
                            <Route path='/step8' element={
                                <Step8/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                        </Routes>
                    </ScapsTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
