import React from "react";
import {Image, Progress} from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import Logo from '../../assets/home/logo.svg';

export const Steps = (props) => {
    return (
        <Box background={'#f6f6f6'} mb={5}>
            <Flex>
                <Box py="3" pl={2}>
                    {props.backLink && (
                        <Link to={props.backLink}>
                            <ArrowBackIcon w={6} h={6} />
                        </Link>
                    )}
                </Box>
                <Box py="2" mx="auto">
                    <Image height={8} src={Logo} alt="SHAPE Logo" />
                </Box>
            </Flex>

            <Progress size='sm' value={props.currentStep} max={props.maxSteps} />
        </Box>
    );
};
