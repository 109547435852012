import {Box, Container, Heading} from "@chakra-ui/react";
import {Steps} from "../steps/Steps";

function Step(props) {
    return (
        <>
            <Box>
                <Steps currentStep={props.currentStep} maxSteps="8" backLink={props.backLink}/>
            </Box>
            <Container p={[2,5]}>
                <Box
                    borderRadius="lg"
                    textAlign={"center"}
                >
                    {props.heading &&
                        <Heading as={"h2"} size={"lg"} mb={5}>
                            {props.heading}
                        </Heading>}

                    {props.description && <p>{props.description}</p>}

                    {props.children}
                </Box>
            </Container>
        </>
    );
}

export default Step;
